export const ThankYou = () => {
    
    return (
        <>
  <section>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1 className="page-title">Thank You!</h1>
          <p>We will get back to you as soon as possible.</p>
        </div>
      </div>
    </div>
  </section>
</>

    )
}